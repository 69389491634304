<template>
    <w-dialog max-width="600" :title="dialogTitle" :value="value" @close="$emit('close')">
        <w-form-builder v-model="formValue" :fields="formFields" :validation.sync="formValidation" />
		<template v-slot:actions>
			<w-flex text-xs-right>
				<w-btn-save :disabled="!formValidation" @click="$emit('save', formValue)" />
			</w-flex>
		</template>
    </w-dialog>
</template>

<script>
export default {
    name: 'MissingParametersDialog',
    props: {
        module: {
            default: null,
            required: false,
            type: Object
        },
        value: {
            required: true,
            type: Boolean
        }
    },
    data: function () {
        return {
            formValidation: false,
            formValue: null
        }
    },
    computed: {
        dialogTitle: function () {
            return this.$t('sa.accounting-firms.module_set_up', {module_name: this.module?.title ? this.module.title : ''})
        },
        formFields: function () {
            if (!this.module || !this.module.accounting_firm_parameters) {
                return []
            }

            return this.module.accounting_firm_parameters
        }
    }
}
</script>